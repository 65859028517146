<template>
  <VDialog
    v-if="isAvailable && notCompletedLessons.length > 0"
    v-model="isConfirm"
    width="400"
  >
    <template #activator="{ on, attrs }">
      <VBtn
        color="accent"
        v-bind="{...contentProps, ...attrs}"
        v-on="on"
      >
        <slot>
          <VIcon left>
            done
          </VIcon>
          {{ $t('complete_training.one') }}
        </slot>
      </VBtn>
    </template>
    <VCard>
      <VCardTitle class="headline">
        {{ $t('complete_training.one') }}?
        <VSpacer />
        <VBtn
          :title="$t('close.one')"
          icon
          @click="isConfirm = false"
        >
          <VIcon>close</VIcon>
        </VBtn>
      </VCardTitle>
      <VDivider />
      <VCardTitle>Вы пропустили уроки:</VCardTitle>
      <VCardText class="text--primary">
        <ul>
          <li
            v-for="step in notCompletedLessons"
            :key="step.id"
            class="my-2"
          >
            {{ step.lesson_type_title }} «{{ step.title }}»
          </li>
        </ul>
      </VCardText>
      <VDivider />
      <VCardActions class="actions">
        <VSpacer />
        <VBtn
          color="primary"
          text
          @click="cancel"
        >
          Вернуться к&nbsp;обучению
        </VBtn>
        <VBtn
          color="accent"
          :loading="accepting"
          @click="accept"
        >
          {{ $t('complete.one') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>

  <VBtn
    v-else-if="isAvailable"
    color="accent"
    v-bind="{...contentProps}"
    :loading="accepting"
    @click="accept"
  >
    <slot>
      <VIcon left>
        done
      </VIcon>
      {{ $t('complete_training.one') }}
    </slot>
  </VBtn>
</template>

<script>
import { acceptSession } from '@/api'

export default {
  name: 'SessionAcceptForm',
  props: {
    session: {
      type: Object,
      required: true
    },

    block: {
      type: Boolean,
      default: false
    },

    large: {
      type: Boolean,
      default: false
    },

    xLarge: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    },

    xSmall: {
      type: Boolean,
      default: false
    },

    contentClasses: {
      type: [String, Object, Array],
      default: ''
    }
  },

  data () {
    return {
      accepting: false,
      isConfirm: false
    }
  },

  computed: {
    contentProps () {
      return {
        class: this.contentClasses,
        block: this.block,
        large: this.large,
        xLarge: this.xLarge,
        small: this.small,
        xSmall: this.xSmall
      }
    },

    notCompletedLessons () {
      return this.session.steps.filter(step => !step.is_completed)
    },

    isAvailable () {
      return this.session.is_active && /* Сессия активна */
        !this.session.settings.accept_manually && /* Завершить программу обучения вручную можно */
        this.session.steps_required_left === 0 /* Все обязательные уроки выполнены */
    }
  },

  methods: {
    cancel () {
      this.isConfirm = false
      this.$router.push({ name: 'session', params: { sessionID: this.session.id } }).catch(() => {})
    },

    async accept () {
      this.accepting = true
      try {
        const { session } = await acceptSession(this.session.id)
        this.$router.push({ name: 'session', params: { sessionID: session.id } }).catch(() => {})
        this.$emit('accept', session)
      } finally {
        this.accepting = false
      }
    }
  }
}
</script>
